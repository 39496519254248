<template>
  <div
    v-if="showToolbar"
    class="flex justify-between w-full px-6 pt-4"
  >
    <!-- Breadcrumbs -->

    <Breadcrumb @select-asset="$emit('select-asset', $event)" />

    <div class="ml-6 bg-gray-100 dark:bg-neutral-900 p-0.5 rounded-lg flex items-center space-x-2">
      <!-- <Create
        v-if="activeFolder"
        v-bind:project="project"
        v-bind:active-folder="activeFolder"
        v-bind:display-mode="displayMode"
      />-->

      <!-- <SortOrder
        v-if="canChangeSort"
        v-bind:sort-order="sortOrder"
        v-on:sort-order-change="$emit('sort-order-change', $event)"
      />-->

      <Filters
        v-if="canChangeLayouts"
        :layout="layout"
        :query="query"
        @layout-change="$emit('layout-change', $event)"
        @query-change="$emit('query-change', $event)"
      />

      <!-- <PanelToggles
        v-if="canChangeLayouts"
        v-bind:info-panel-open="infoPanelOpen"
        v-bind:tree-panel-open="treePanelOpen"
        v-on:toggle-panel-state="$emit('toggle-panel-state', $event)"
      />-->
    </div>
  </div>

  <!--<div>
    <div
      class="flex items-center justify-between py-2 border-b border-gray-300 dark:border-gray-800"
      v-if="showToolbar"
    >
      <Navigation v-on:select-asset="$emit('select-asset', $event)" />

      <div class="flex items-center">
        <SortOrder
          v-if="canChangeSort"
          v-bind:sort-order="sortOrder"
          v-on:sort-order-change="$emit('sort-order-change', $event)"
        />
        <Filters
          v-if="canChangeLayouts"
          v-bind:layout="layout"
          v-on:layout-change="$emit('layout-change', $event)"
          v-bind:query="query"
          v-on:query-change="$emit('query-change', $event)"
        />
      </div>
    </div>
  </div>-->
</template>

<script>
const Filters = () => import('./assets-explorer-toolbar/assets-explorer-toolbar-filters.vue')
const PanelToggles = () => import('./assets-explorer-toolbar/assets-explorer-toolbar-panel-toggles.vue')
const Navigation = () => import('./assets-explorer-toolbar/assets-explorer-toolbar-navigation.vue')
const Breadcrumb = () => import('./assets-explorer-toolbar/assets-explorer-toolbar-breadcrumb.vue')
const SortOrder = () => import('./assets-explorer-toolbar/assets-explorer-toolbar-sort.vue')
const Create = () => import('./assets-explorer-toolbar/assets-explorer-toolbar-create.vue')

export default {
  name: 'AssetsExplorerToolbar',

  components: {
    Filters,
    PanelToggles,
    Navigation,
    Breadcrumb,
    SortOrder,
    Create
  },

  props: {
    layout: {
      required: true,
      type: String
    },
    project: {
      required: true,
      type: Object
    },
    query: {
      required: true,
      type: String
    },
    sortOrder: {
      required: true,
      type: String
    },
    activeFolder: {
      required: true,
      type: Object
    },
    canUpload: {
      required: true,
      type: Boolean
    },
    displayMode: {
      default: 'full',
      type: String
    },
    treePanelOpen: {
      required: true,
      type: Boolean
    },
    infoPanelOpen: {
      required: true,
      type: Boolean
    }
  },

  computed: {

    showToolbar() {
      // Only hide the toolbar in mini mode, and at the root folder
      // if (this.displayMode === 'mini' && this.isRoot) return false
      return true
    },

    isRoot() {
      return this.activeFolder.parent === null
    },

    canChangeLayouts() {
      return this.displayMode === 'full'
    },

    canChangeSort() {
      return this.displayMode !== 'mini'
    },

    canSeeCreate() {
      return this.displayMode === 'full'
    }
  }
}
</script>
